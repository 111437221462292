import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // API base URL from environment variables
    // The imageBaseUrl shouldn't be part of the axios instance, handle it separately in your app if needed
    // For CORS and session handling:
    withCredentials: true, // Allow sending credentials (cookies, sessions, etc.)
    headers: {
        'X-Requested-With': 'XMLHttpRequest' // To make the request an XMLHttpRequest (for CSRF protection, etc.]
    }
});

export default api;