<script setup>
import { ref } from 'vue';
const isFirstLogin = ref(false);

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
if(sessionStorage.getItem('is_first_login')) {
  isFirstLogin.value = true;
}

function forgetSession() {
  sessionStorage.removeItem('is_first_login');
  isFirstLogin.value = false;
}
</script>

<template>
    <Dialog v-model="isFirstLogin" header="Update Your Password" :visible="isFirstLogin" :modal="true" :closable="false" :style="{width: '50vw'}">
        <p>
        Looks like you haven't updated your password yet. We recommend you to update your password for security reasons.
        </p>
        <div class="buttons flex justify-end">
        <Button label="Later" @click="forgetSession"  class="mr-3" severity="warn"/>
        <router-link to="/password" class="mr-3" @click="forgetSession">
        <Button label="Update Password"  />
        </router-link>
        </div>
    </Dialog>
</template>


<style>

</style>