import { defineStore } from 'pinia';
import api from '@/utils/api';

export const useMessageStore = defineStore('message', {
    state: () => ({
        message: '',
        unreadNotificationsCount: 0,
        // userType: ''
    }),
    actions: {
        setMessage(msg) {
            this.message = msg;
        },
        clearMessage() {
            this.message = '';
        },
        setUserType(userType) {
            this.userType = userType;
        },
        clearUserType() {
            this.userType = '';
        },
        // async fetchUserType() {
        //     const response = await api.get('/get-profile', {
        //         headers: {
        //             Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
        //         }
        //     });
        //     return response.data.result.result.user_type;
        // },
        async fetchUser() {
            try {
                const response = await api.get('/get-profile', {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
                    }
                });

                console.log(response.data.result.result);
                return response.data.result.result;
                // this.userType = response.data.result.result.user_type;
            } catch (error) {
                console.error(error);
            }
        },
        setUnreadNotificationsCount(count) {
            // console.log("count: " + count);
            this.unreadNotificationsCount = count;
        },
        getUnreadNotificationsCount() {
            return this.unreadNotificationsCount;
        }
    }
});