<script setup>
import api from "@/utils/api";
import { onMounted, ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';


const isLoading = ref(true);
// Holds the full menu model
const fullModel = ref([
    {
        label: 'GENERAL',
        userType: 'admin',
        items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
            { label: 'Gurus', icon: 'pi pi-fw pi-user', to: '/admin/gurus' },
            { label: 'Refund Requests', icon: 'pi pi-fw pi-wallet', to: '/admin/refund-request' },
            { label: 'Horoscope', icon: 'pi pi-fw pi-moon', to: '/admin/horoscope' },
        ]
    },

    {
        label: 'GENERAL',
        userType: 'guru', 
        items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Manage Slots', icon: 'pi pi-fw pi-calendar', to: '/guru/slots' },
            { label: 'Client Bookings', icon: 'pi pi-fw pi-user', to: '/guru/clients' },
        ]
    },

    {
        label: 'GENERAL',
        userType: 'client', 
        items: [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' },
            { label: 'Browse Gurus', icon: 'pi pi-fw pi-user', to: '/gurus' },
            { label: 'My Bookings', icon: 'pi pi-fw pi-calendar', to: '/my-bookings' },
            { label: 'My Payments', icon: 'pi pi-fw pi-wallet', to: '/my-payments' },
            { label: 'Horoscope', icon: 'pi pi-moon', to: '/horoscope' },
        ]
    },

    {
        label: 'PROFILE AND SECURITY',
        userType: 'all', 
        items: [
            { label: 'Profile', icon: 'pi pi-fw pi-user', to: '/profile' },
            { label: 'Update Password', icon: 'pi pi-fw pi-cog', to: '/password' },
        ]
    },
]);

const model = ref([]);

onMounted(async () => {
    try {
        if (!sessionStorage.getItem('apiToken')) {
            return;
        }
        const response = await api.get('/get-profile', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
            }
        });
        const userType = response.data.result.result.user_type;
        isLoading.value = false;
        model.value = fullModel.value.filter(
            section => section.userType === userType || section.userType === 'all'
        );
    } catch (error) {
        console.error('Failed to fetch user profile:', error);
    }
});
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item.label">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>

    <ul class="layout-menu" v-if="isLoading">
        <li class="menu-item">
            <div class="menu-item-content" v-for="n in 6" :key="n">
                <div class="menu-icon">
                    <i class="pi pi-spin pi-spinner"></i>
                </div>
                <div class="menu-text">
                    <span class="menu-text"></span>
                </div>
            </div>
        </li>
    </ul>
    
        
</template>

<style scoped>
    .menu-item-content {
        display: flex;
        align-items: center;
        padding: 1rem;
    }

    .menu-icon {
        margin-right: 1rem;
    }

    .menu-text {
        display: inline-block;
        width: 100px;
        height: 1rem;
        background-color: #f0f0f0;
    }

</style>
