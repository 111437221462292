import AppLayout from '@/layout/AppLayout.vue';
import api from "@/utils/api";

import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/dashboard',
            component: AppLayout,
            children: [{
                    path: '/dashboard',
                    name: 'dashboard',
                    component: () =>
                        import ('@/views/Dashboard.vue'),
                    meta: { requiresAuth: true }
                },
                // admin routes
                {
                    path: '/admin/gurus',
                    name: 'guru.index',
                    component: () =>
                        import ('@/views/pages/admin/guru/GurusList.vue'),
                    meta: { requiresAuth: true, userType: 'admin' }
                },

                // guru routes
                {
                    path: '/guru/slots',
                    name: 'slot.index',
                    component: () =>
                        import ('@/views/pages/guru/slots/SlotsPage.vue'),
                    meta: { requiresAuth: true, userType: 'guru' }
                },
                {
                    path: '/guru/clients',
                    name: 'client.index',
                    component: () =>
                        import ('@/views/pages/Call.vue'),
                    meta: { requiresAuth: true, userType: 'guru' }
                },

                {
                    path: '/call',
                    name: 'call',
                    component: () =>
                        import ('@/views/pages/Call.vue'),
                    meta: { requiresAuth: true }
                },

                // client routes
                {
                    path: '/gurus',
                    name: 'gurus.index',
                    component: () =>
                        import ('@/views/pages/client/gurus/GuruList.vue'),
                    meta: { requiresAuth: true, userType: 'client' }
                },
                {
                    path: '/guru/:id',
                    name: 'gurus.show',
                    component: () =>
                        import ('@/views/pages/client/gurus/GuruDetail.vue'),
                    meta: { requiresAuth: true, userType: 'client' }
                },
                {
                    path: '/my-bookings',
                    name: 'bookings.index',
                    component: () =>
                        import ('@/views/pages/Call.vue'),
                    meta: { requiresAuth: true, userType: 'client' }
                },
                {
                    path: '/my-payments',
                    name: 'payments.index',
                    component: () =>
                        import ('@/views/pages/PaymentsList.vue'),
                    meta: { requiresAuth: true, userType: 'client' }
                },

                // profile routes
                {
                    path: '/profile',
                    name: 'profile',
                    component: () =>
                        import ('@/views/pages/auth/ProfilePage.vue'),
                    meta: { requiresAuth: true }
                },
                {
                    path: '/password',
                    name: 'password',
                    component: () =>
                        import ('@/views/pages/auth/PasswordPage.vue'),
                    meta: { requiresAuth: true }
                },
                {
                    path: '/notifications',
                    name: 'notifications',
                    component: () =>
                        import ('@/views/pages/NotificationView.vue'),
                },

                {
                    path: 'test-payment',
                    name: 'test-payment',
                    component: () =>
                        import ('@/views/pages/TestPayment.vue'),
                },
                {
                    path: '/admin/refund-request',
                    name: 'refund-request',
                    component: () =>
                        import ('@/views/pages/RefundRequest.vue'),
                    meta: { requiresAuth: true, userType: 'admin' }
                },
                {
                    path: '/guru/:id/reschedule/:slotBookingIdForReschedule',
                    name: 'gurus.reschedule',
                    component: () =>
                        import ('@/views/pages/client/gurus/Reschedule.vue'),
                    meta: { requiresAuth: true, userType: 'client' },
                    beforeEnter: (to, from, next) => {
                        // Check for the required query parameter
                        if (to.query.cancel_and_reschedule) {
                            next(); // Proceed to the component
                        } else {
                            // Redirect to an error route or handle the absence
                            next({ name: 'some.error.route' }); // Replace with your error route
                        }
                    }
                },
                {
                    path: '/admin/horoscope',
                    name: 'admin.horoscope',
                    component: () =>
                        import ('@/views/pages/admin/ZodiacHoroscope.vue'),
                    meta: { requiresAuth: true, userType: 'admin' }
                },
                {
                    path: '/horoscope',
                    name: 'horoscope',
                    component: () =>
                        import ('@/views/pages/Horoscope.vue'),
                    meta: { requiresAuth: true, userType: 'client' }
                },
            ]
        },

        {
            path: '/',
            name: 'home',
            component: () =>
                import ('@/views/pages/HomePage.vue')
        },

        {
            path: '/book-appointment',
            name: 'book-appointment',
            component: () =>
                import ('@/views/pages/BookAppointment.vue')
        },

        {
            path: '/login',
            name: 'login',
            component: () =>
                import ('@/views/pages/auth/LoginPage.vue')
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import ('@/views/pages/auth/RegisterPage.vue')
        },
        {
            path: '/pages/notfound',
            name: 'notfound',
            component: () =>
                import ('@/views/pages/NotFound.vue')
        },
        {
            path: '/access',
            name: 'accessDenied',
            component: () =>
                import ('@/views/pages/auth/AccessPage.vue')
        },
        {
            path: '/error',
            name: 'error',
            component: () =>
                import ('@/views/pages/auth/ErrorPage.vue')
        },
        {
            path: '/class',
            name: 'class',
            component: () =>
                import ('@/views/pages/Class.vue'),
        },
        {
            path: '/call',
            name: 'call',
            component: () =>
                import ('@/views/pages/Call.vue'),
        },

    ]
});

router.beforeEach(async(to, from, next) => {
    const isAuthenticated = !!sessionStorage.getItem('apiToken'); // Check if apiToken is present
    //log the route
    // console.log(to);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next({ name: 'login' });
        } else {
            try {
                const response = await api.get('/get-profile', {
                    headers: {
                        Authorization: `Bearer ${ sessionStorage.getItem('apiToken')}`
                    }
                });

                const data = response.data.result.result;
                const userType = data.user_type;
                // const guruId = data.guru_id ? data.guru_id : null;

                const routeWithUserType = to.matched.find(record => record.meta.userType);

                // If the route has a specific userType requirement, check it
                if (routeWithUserType) {
                    if (routeWithUserType.meta.userType !== userType) {
                        next({ name: 'accessDenied' });
                    } else {
                        next(); // Allow access if the userType matches
                    }
                } else {
                    // If no specific userType is required, allow access
                    next();
                }
            } catch (error) {
                console.error(error);
                next({ name: 'error' });
            }
        }
    } else {
        next(); // Proceed to the route if it doesn't require authentication
    }
});


export default router;