// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import echo from "./services/pusher";
import { createPinia } from "pinia";
import { PrimeVue } from "@primevue/core";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import api from "./utils/api";


import Aura from "@primevue/themes/aura";
import "@/assets/styles.scss";
import "@/assets/tailwind.css";
// import VCalendar from 'v-calendar';
import { setupCalendar, Calendar, DatePicker } from "v-calendar";
import "v-calendar/style.css";
import VueKhalti from 'vue-khalti'

const app = createApp(App);
app.use(createPinia());

import { useMessageStore } from "./stores/messageStore";
const messageStore = useMessageStore();

// const authUserType = messageStore.fetchUser();

// Make Echo available globally
app.config.globalProperties.$echo = echo;

app.use(router);
app.use(PrimeVue, {
    theme: {
        preset: Aura,
        options: {
            darkModeSelector: ".app-dark",
        },
    },
});
app.use(ToastService);
app.use(ConfirmationService);
// app.use(VCalendar, {});
app.use(setupCalendar, {});
app.component("VCalendar", Calendar);
app.component("VDatePicker", DatePicker);
app.config.globalProperties.$appUrl = process.env.VUE_APP_MAIN_URL;

const authUser = sessionStorage.getItem('apiToken');

export async function getRecent3NotificationsAndCount() {
    try {
        const response = await api.get('/get-recent-3-notifications', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
            }
        })
        console.log('response: ');
        console.log(response.data.notifications_count);

        messageStore.setUnreadNotificationsCount(response.data.notifications_count);
    } catch (error) {
        console.error(error);
    }
}

if (authUser) {
    try {
        const response = await api.get('/get-profile', {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
            }
        });
        const data = response.data.result.result;
        // const userType = data.user_type;
        // const userId = data.id;
        const guruId = data.guru_id ? data.guru_id : null;
        app.config.globalProperties.$userType = data.user_type;
        app.config.globalProperties.$userId = data.id;
        app.config.globalProperties.$guruId = data.guru_id ? data.guru_id : null;
        echo.connector.pusher.connection.bind("connected", function() {
            console.log("connected");
        });

        echo.connector.pusher.connection.bind("disconnected", function() {
            console.log("disconnected");
        });


        if (guruId) {
            // console.log(`appointment.${guruId}`);
            echo.private(`appointment.${guruId}`).listen('AppointmentBooked', (e) => {
                console.log('appointment booked listened');
                console.log(e.message);
                getRecent3NotificationsAndCount();
            });
        }
        getRecent3NotificationsAndCount();
    } catch (error) {
        console.error(error);
    }
}

app.use(VueKhalti);
app.mount("#app");