<script setup>
import {useLayout} from '@/layout/composables/layout';
import {ref, computed} from "vue";
import { useRouter } from 'vue-router';
import { useMessageStore } from '@/stores/messageStore';

const router = useRouter();

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import OverlayBadge from 'primevue/overlaybadge';

const {onMenuToggle} = useLayout();

const displayConfirmation = ref(false);

const messageStore = useMessageStore();

const unreadNotificationsCount = computed(() => messageStore.getUnreadNotificationsCount());

const logout = () => {
  sessionStorage.removeItem('apiToken');
  location.href = '/login';
};

function openConfirmation() {
  console.log("hello from logout confirmation");
  displayConfirmation.value = true;
}

function closeConfirmation() {
  displayConfirmation.value = false;
}
</script>

<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </button>
      <router-link to="/" class="layout-topbar-logo">
        <img src="https://i.ibb.co/Xty4DHw/1.png" alt="logo" width="130px"/>
      </router-link>
    </div>

    <div class="layout-topbar-actions">
      <!-- <div class="layout-config-menu">
          <button type="button" class="layout-topbar-action" @click="toggleDarkMode">
              <i :class="['pi', { 'pi-moon': isDarkTheme, 'pi-sun': !isDarkTheme }]"></i>
          </button>
          <div class="relative">
              <button
                  v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
                  type="button"
                  class="layout-topbar-action layout-topbar-action-highlight"
              >
                  <i class="pi pi-palette"></i>
              </button>
              <AppConfigurator />
          </div>
      </div> -->

      <button
          class="layout-topbar-menu-button layout-topbar-action"
          v-styleclass="{ selector: '@next', enterFromClass: 'hidden', enterActiveClass: 'animate-scalein', leaveToClass: 'hidden', leaveActiveClass: 'animate-fadeout', hideOnOutsideClick: true }"
      >
        <i class="pi pi-ellipsis-v"></i>
      </button>

      <div class="layout-topbar-menu hidden lg:block">
        <div class="layout-topbar-menu-content">
          <OverlayBadge v-if="unreadNotificationsCount > 0" :value="unreadNotificationsCount" severity="danger">
            <button type="button" class="layout-topbar-action" @click="router.push('/notifications')">
                <i class="pi pi-bell" />
              <span>Notification</span>
            </button>
          </OverlayBadge>
          <button v-else type="button" class="layout-topbar-action" @click="router.push('/notifications')">
              <i class="pi pi-bell" />
            <span>Notification</span>
          </button>
          <router-link to="/profile" class="layout-topbar-action">
            <i class="pi pi-user"></i>
            <span>Profile</span>
          </router-link>
          <button type="button" class="layout-topbar-action" @click="openConfirmation()">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
          </button>
        </div>
      </div>
      <Dialog header="Confirmation" v-model:visible="displayConfirmation" :style="{ width: '350px' }" :modal="true">
        <div class="flex items-center justify-center">
          <i class="pi pi-exclamation-triangle mr-4" style="font-size: 2rem"/>
          <span>Are you sure you want to logout?</span>
        </div>
        <template #footer>
          <Button label="No" icon="pi pi-times" @click="closeConfirmation" text severity="secondary"/>
          <Button label="Yes" icon="pi pi-check" @click="logout()" severity="danger" outlined autofocus/>
        </template>
      </Dialog>
    </div>
  </div>
</template>
