// src/services/pusher.js
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
const echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    forceTLS: true,
    authEndpoint: process.env.VUE_APP_AUTH_END_POINT,
    auth: {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem('apiToken')}`
        }
    }
});


export default echo;